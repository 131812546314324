.profile-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: calc(100% - 20px - 20px);

  .form-container {
    width: calc(100% - 20px - 20px);
    overflow-x: hidden;

    .input {
      width: 100%;
      margin-right: 50%;
      max-width: 500px;
    }
    .admin-form-button-container {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
