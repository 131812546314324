.forgot-password-confirm-page {
  background-color: #a8ccab;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .form-container {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .forgot-password-confirm-submit-button {
      width: fit-content;
      margin-left: 55%;
    }
  }

  .forgot-password-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 15px);
    span {
      text-align: center;
      font-size: 24px;
      color: #ffffff;
    }
  }
}
