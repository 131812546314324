.fund-page {
  width: calc(100% - 20px - 20px);

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    position: relative;
    width: calc(100% - 20px - 20px);
    @media (max-width: 776px) {
      margin-bottom: 20px;
    }
    .link-buttons {
      display: flex;
      justify-content: center;
      @media (max-width: 776px) {
        width: calc(100% - 80px);
      }

      .selector-button {
        border-radius: 24px;
        min-height: 32px;
        height: fit-content;
        margin-right: 10px;
        font-weight: bold;
        border: 1px solid transparent;
        background-color: transparent;
        box-shadow: 0px 16px 16px 0px rgba(73, 92, 136, 0.15);

        cursor: pointer;

        &.active {
          background-color: var(--color-green-active-button);
        }

        @media (max-width: 776px) {
          margin-right: 0px;
        }

        .link {
          text-decoration: none;
          font-family: var(--font-primary);
          font-weight: bold;
          color: var(--font-color);

          &.active {
            text-decoration: underline;
          }

          @media (max-width: 776px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }

    .creation-container {
      position: absolute;
      right: 0;
      bottom: 0;

      .fund-creation-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        font-size: 16px;
        text-align: center;
        line-height: 18px;
        padding: 0;
        width: 40px;
        box-shadow: 0px 16px 16px 0px rgba(73, 92, 136, 0.15);
      }
    }
  }
}
