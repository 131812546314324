.admin-page {
  width: calc(100% - 20px - 20px);

  .searchbar-container {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 20px - 20px);
    margin-left: 20px;

    .searchbar-button {
      margin-left: 15px;
      margin-top: -15px;

      @media (max-width: 776px) {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
      }
    }
  }
}
