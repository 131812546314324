.documents-page {
  width: calc(100% - 20px - 20px);

  .export-button {
    width: fit-content;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-color: var(--color-light-green);
    font-size: 16px;
    float: right;
    margin-right: 16px;
    cursor: pointer;
    color: var(--color-primary);
    font-weight: 600;
    &:hover {
      background-color: var(--color-green-hover);
    }
  }

  .searchbar-container {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 20px - 20px);
    margin-left: 20px;

    .searchbar-button {
      margin-left: 15px;
      margin-top: -15px;

      @media (max-width: 776px) {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
      }
    }
  }
}

.ant-table-filter-dropdown-btns {
  .ant-btn {
    border-radius: 20px;
    background-color: var(--color-green-main);
    font-family: var(--font-primary);
    &:hover {
      background-color: var(--color-green-hover) !important;
    }
  }
  .ant-btn-link {
    color: var(--color-primary);
    &:hover {
      background-color: var(--color-green-hover) !important;
      color: var(--color-primary) !important;
    }
  }
}

.audio-container {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.filename {
  font-size: 14px;
  margin: 4px 0 8px 4px;
}

.popover {
  margin: 0px 8px 0px 0px;
}

.field__error-message {
  margin-top: 10px;
}
